import { memo } from 'react';
import { TouchableOpacity, Text } from 'app/native';
import usePerson from 'app/hooks/usePerson';
import useTranslation from 'app/hooks/useTranslation';
import classnames from 'classnames';
import usePersonOnboarding from 'app/hooks/usePersonOnboarding'
import useDrawer from 'app/hooks/useDrawer';
import { FontAwesomeIcon } from 'app/icon';
import { Platform } from 'react-native';
import { useLogin } from 'app/hooks/useLogin';

export default memo(() => {
  const user = usePerson();
  const { t } = useTranslation();
  const doLogin = useLogin();

  const drawer = useDrawer();

  const [nextStep] = usePersonOnboarding();
  const weAreNext = nextStep === 'login';

  return user ? (
    <TouchableOpacity
      onPress={() => {
        if (user.ephemeral) {
          doLogin();
        } else {
          drawer.openDrawer();
        }
      }}
      className={classnames("h-10 py-2 px-3 flex flex-row items-center space-x-1.5", {
        "bg-brand-primary shadow-lg rounded": weAreNext,
        "pl-0": !weAreNext,
        "mt-3": user.ephemeral,
        "mt-2.5": !user.ephemeral,
      })} >
      {
        user.ephemeral ? (
          <>
            <FontAwesomeIcon icon={[weAreNext ? "fas" : "fal", "user"]} className={`w-5 h-5 ${weAreNext ? "text-white" : "text-brand-black-700"}`} />
            {
              user.ephemeral ? (
                <Text className={classnames("", {
                  "text-white font-t-bold": weAreNext,
                  "text-brand-black-700 font-t-medium": !weAreNext,
                })}>{t('login.login')}</Text>
              ) : (
                null
              )
            }
          </>
        ) : Platform.OS == "web" ? (
          <FontAwesomeIcon icon={["fal", "xmark"]} className="outline-none w-6 h-6 text-brand-black-700" />
        ) : (
          <FontAwesomeIcon icon={["fal", "bars"]} className="w-6 h-6 outline-none text-brand-black-700" />
        )
      }
    </TouchableOpacity >
  ) : null;
});

