import { Link, Text, TouchableOpacity } from "app/native";
import { FontAwesomeIcon, IconProp } from 'app/icon'
import classNames from "classnames";
import { styled } from "nativewind";
import { useMemo } from "react";

type Mode = "primary" | "secondary" | "transparent" | "primary-disabled" | "secondary-disabled" | "transparent-disabled";
type Size = "small" | "large";

const Component = ({ style, children, transparent, secondary, text, icon, iconLast, textClasses, iconClasses, onPress, href, disabled, size = "large" }: { style?: any, children?: any, icon?: IconProp, iconLast?: boolean, text?: string | null, textClasses?: string, iconClasses?: string, onPress?: () => void, transparent?: boolean, secondary?: boolean, disabled?: boolean, size?: Size, href?: string }) => {

  const mode = useMemo(() => {
    const baseMode = transparent ? "transparent" : secondary ? "secondary" : "primary";
    return disabled ? `${baseMode}-disabled` as Mode : baseMode as Mode;
  }, [transparent, secondary, disabled])

  const backgroundColor = useMemo(() => {
    switch (mode) {
      case "primary":
        return "bg-brand-primary shadow-sm";
      case "secondary":
        return "bg-brand-black-100 shadow-sm";
      case "transparent":
        return "bg-transparent";
      case "primary-disabled":
        return "bg-brand-primary/50";
      case "secondary-disabled":
        return "bg-brand-black-100";
      case "transparent-disabled":
        return "bg-transparent";
    }
  }, [mode])

  const textColor = useMemo(() => {
    switch (mode) {
      case "primary":
        return "text-white";
      case "secondary":
        return "text-brand-black-900";
      case "transparent":
        return "text-brand-black-900";
      case "primary-disabled":
        return "text-white";
      case "secondary-disabled":
        return "text-brand-black-600";
      case "transparent-disabled":
        return "text-brand-black-600";
    }
  }, [mode])

  const classes = classNames("flex flex-row items-center justify-center space-x-2 hover:shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-primary", backgroundColor, {
    "py-2 px-6 rounded-lg": size === "large",
    "py-1 px-4 rounded": size === "small",
  })

  const useTextClasses = classNames("font-t-semi tracking-wider text-center", textColor, {
    "text-xl": size === "large",
    "text-base": size === "small",
  }, textClasses)

  const useIconClasses = classNames({
    "w-6 h-6": size === "large",
    "w-5 h-5": size === "small",
  }, textColor, iconClasses)

  const childrenOrtextAndIcon = useMemo(() => {
    return children ? children : (
      <>
        { icon && !iconLast ? <FontAwesomeIcon icon={icon} className={useIconClasses} /> : null}
        <Text className={useTextClasses}>{text}</Text>
        { icon && iconLast ? <FontAwesomeIcon icon={icon} className={useIconClasses} /> : null}
      </>
    )
  }, [text, icon, children])

  return href ? (
    <Link href={href} className={classes} style={style}>
      {
        childrenOrtextAndIcon
      }
    </Link>

  ) : (
    <TouchableOpacity className={classes} onPress={disabled ? () => { } : onPress} style={style} >
      {
        childrenOrtextAndIcon
      }
    </TouchableOpacity >
  );
};

export const Button = styled(Component);

