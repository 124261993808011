import NMarkdown, { MarkdownIt } from 'react-native-markdown-display';
import MarkdownItEmoji from 'markdown-it-emoji';
import { removeMdPlugin } from 'markdown-it-remove';
import { Pressable, Text } from 'app/native';
import emojiRegex from 'emoji-regex'
import { ReactNode, useCallback } from 'react';
import { Platform, Linking } from 'react-native';
import { useRouter } from 'solito/router';
import { appDomain } from 'app/lib/config';

export const strip = (text: string) => {
  if (!text) return "";

  const md = MarkdownIt().use(removeMdPlugin, {});
  return md.render(text);
}

export const Markdown = ({
  text,
  color,
  center,
  fontSize,
  lineHeight,
  oneLine,
  onLinkLongPress,
}: {
  center?: boolean,
  oneLine?: boolean,
  text: string,
  color?: string,
  fontSize: number,
  lineHeight?: number,
  onLinkLongPress?: (event: any) => void,
}) => {
  const { push } = useRouter();

  const onLinkPress = useCallback((url: string) => {
    // If url is to appDomain(), push to router
    const parsed = new URL(url);
    const urlDomainAndPort = [parsed.hostname, parsed.port].filter(Boolean).join(":");
    if (urlDomainAndPort == appDomain()) {
      push(parsed.pathname);
      return false;
    }

    // return true to open with `Linking.openURL
    // return false to handle it yourself
    Linking.openURL(url);
  }, [push]);

  const md = MarkdownIt({ linkify: true, typographer: true })
    .use(MarkdownItEmoji)
    .disable([]);

  const renderEmoji = (emoji: any, styles: any, key: any) => {
    return <Text
      key={key}
      style={{
        fontSize: styles.body.fontSize,
        lineHeight: styles.body.lineHeight,
        fontFamily: "System",
      }}
    >{emoji}</Text>;
  };

  const trimmed = text?.trim();

  // @ts-ignore
  return !trimmed || trimmed == "" ? null : <NMarkdown
    markdownit={md}
    rules={{
      emoji: (node, _children, _parent, styles) => {
        return renderEmoji(node.content, styles, node.key);
      },
      text: (node, _children, _parent, styles, inheritedStyles = {}) => {
        // Get emojis
        const match = node.content.match(emojiRegex());

        // Split the text
        const split = node.content.split(emojiRegex());

        // Now combine the text and emojis
        const result = split.map((text, index) => {
          let emoji: ReactNode | null = null;
          if (match && match[index]) {
            emoji = renderEmoji(match[index], styles, `${node.key}-${index}`);
            return [text, emoji];
          }
          return [text];
        }).flat();

        return (<Text key={node.key} style={[inheritedStyles, styles.text]}>
          {result}
        </Text>);
      },
      link: (node, children, _parent, styles) => {
        return (
          <Pressable key={node.key} style={styles.link} onLongPress={onLinkLongPress} onPress={() => onLinkPress(node.attributes.href)}>
            {children}
          </Pressable>
        );
      },
    }}
    style={{
      body: {
        fontFamily: "InterRegular",
        lineHeight: lineHeight || fontSize * 1.5,
        color: color,
        fontSize: fontSize,
        textAlign: center ? 'center' : 'left',
      },
      strong: {
        fontFamily: "InterBold",
      },
      em: {
        fontFamily: "InterItalic",
      },
      link: Platform.OS == "web" ? {
        fontFamily: "InterRegular",
        // @ts-ignore
        wordBreak: 'break-word',
      } : {
        fontFamily: "InterRegular",
      },
      paragraph: {
        marginTop: 0,
        marginBottom: oneLine ? 0 : 10,
      },
      ordered_list: {
        marginTop: 0,
        marginBottom: 10,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
      },
      unordered_list: {
        marginTop: 0,
        marginBottom: 10,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
      },
      list_item: {
        marginBottom: 10,
        marginLeft: -10,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
      },
    }}>
    {trimmed}</NMarkdown>
}

