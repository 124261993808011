import SplashLogo from 'app/components/SplashLogo';
import { useEffect, useState, useCallback, memo } from "react";
import { StatusBar } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { AnimatePresence, MotiView, useAnimationState } from 'moti';
import {
  StyleSheet,
  View,
  Platform,
  useWindowDimensions,
} from "react-native";

export default memo(({ children, done, runningCallback, appIsReady, run }: { children: React.ReactElement, done: () => void, runningCallback?: () => void, appIsReady: boolean, run: boolean }) => {
  const [isSplashAnimationComplete, setAnimationComplete] = useState(!run);
  const [logoAnimationDone, setLogoAnimationDone] = useState(!run);
  const { height } = useWindowDimensions();

  const animationState = useAnimationState({
    from: {
      height: height * 2,
    },
    to: {
      height: height,
    }
  })

  useEffect(() => {
    if (appIsReady) {
      animationState.transitionTo('to');
    }
  }, [appIsReady]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // console.log("SAFETY HATCH setLogoAnimationDone(true)");
      setLogoAnimationDone(true);
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  const onLayout = useCallback(() => {
    if (runningCallback) {
      runningCallback();
    }
  }, [runningCallback]);

  useEffect(() => {
    if (logoAnimationDone) {
      done();
      setAnimationComplete(true)
    }
  }, [logoAnimationDone]);

  useEffect(() => {
    if (isSplashAnimationComplete) {
      setTimeout(() => {
        StatusBar.setBarStyle("dark-content");
      }, 500);
    } else {
      StatusBar.setBarStyle("light-content");
    }
    if (Platform.OS == "android") {
      StatusBar.setBackgroundColor("#00000000");
    }
  }, [isSplashAnimationComplete]);

  const animationDoneCallback = useCallback(() => {
    setLogoAnimationDone(true);
  }, []);

  // console.log(`AnimatedSplashScreen: ${JSON.stringify({ isSplashAnimationComplete, logoAnimationDone, appIsReady }, null, 2)}`);
  return (
    <View style={{ flex: 1 }}>
      {children}
      <AnimatePresence>
        {isSplashAnimationComplete ? null : (
          <MotiView
            style={[
              StyleSheet.absoluteFill,
              {
                // marginTop: Constants.statusBarHeight,
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: height,
                overflow: 'hidden',
              },
            ]}
            from={{
              opacity: 1,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            exitTransition={{
              type: "timing",
              duration: 500,
            }}
          >
            <MotiView
              state={animationState}
              transition={{
                type: "timing",
                duration: 800,
                delay: 1400,
              }}
              style={{
                top: 0,
                position: 'absolute',
                width: '100%',
              }}
            >
              <LinearGradient
                onLayout={onLayout}
                // Background Linear Gradient
                colors={["#4AC4A9", "#A0C4B0", "#F6C4B7"]}
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            </MotiView>
            {appIsReady ? <SplashLogo done={animationDoneCallback} /> : null}
          </MotiView>
        )}
      </AnimatePresence>
    </View>
  );
});

