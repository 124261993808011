import { Player } from '@lottiefiles/react-lottie-player';

export default ({done}: {done: () => void}) => {
  return <Player
    autoplay
    keepLastFrame
    onEvent={e => {
      if (e === "complete") {
        done();
      }
    }}
    src="/lottie/name_big_splash.json"
    style={{ height: '170px', width: '270px' }}
  />;
};
