import { useEffect } from 'react';
import * as AuthSession from 'expo-auth-session';
const logoutRedirectUri = AuthSession.makeRedirectUri({
  path: '',
});
import { logout } from 'app/lib/mainframeFetch';
import confirm from 'app/lib/confirm';
import useTranslation from 'app/hooks/useTranslation';
import { useToken } from 'app/hooks/useToken';
import { useDispatch } from 'react-redux'
import { reset } from 'app/slices/projections';

export default () => {
  const { t } = useTranslation();
  const [_token, setToken] = useToken();
  const dispatch = useDispatch();
  const [_logoutRequest, logoutResult, logoutPromptAsync] = AuthSession.useAuthRequest({
    clientId: 'oase.app',
    responseType: AuthSession.ResponseType.IdToken,
    redirectUri: logoutRedirectUri,
  }, {
    authorizationEndpoint: "https://promiseauthentication.org/logout",
  });

  const resetStore = () => {
    dispatch(reset());
  };

  useEffect(() => {
    if (logoutResult) {
      logout().then(() => { resetStore(); setToken(null); });
    }
  }, [logoutResult]);

  const confirmLogout = () => {
    confirm(t('login.logout.confirm.title') as string, t('login.logout.confirm.message') as string, [
      {
        text: t('login.logout.confirm.cancel') as string,
        onPress: () => { },
        style: 'cancel',
      },
      {
        text: t('login.logout.confirm.ok') as string,
        onPress: () => logoutPromptAsync({}),
      },
    ], {});
  };

  const openDrawer = () => {
    confirmLogout();
  }

  const closeDrawer = () => {
  }

  const toggleDrawer = () => {
  }

  return {
    openDrawer,
    closeDrawer,
    toggleDrawer,
  }
}

