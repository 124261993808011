import { useCallback, useEffect, useMemo } from "react";
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { signIn } from 'app/lib/mainframeFetch';
import useTranslation from 'app/hooks/useTranslation';
import { useDispatch } from 'react-redux'
import { reset } from 'app/slices/projections';
import { useToken } from 'app/hooks/useToken';
import { Platform } from 'react-native';

interface TokenResult {
  id_token: string;
}

interface AuthResult {
  params?: TokenResult;
}

const PROMISE_ENDPOINT = "https://promiseauthentication.org/a/oase.app";

WebBrowser.maybeCompleteAuthSession();

export const useLogin = () => {
  const [_token, setToken] = useToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const resetStore = () => {
    dispatch(reset());
  };

  const redirectUri = useMemo(() => {
    if (Platform.OS == "web") {
      return AuthSession.makeRedirectUri({
        path: `authenticate?andThen=${window.location.href}`,
      });
    } else {
      return AuthSession.makeRedirectUri({
        path: `authenticate`,
      });
    }
  }, []);

  const loginUrl = useMemo(() => {
    const url = `${PROMISE_ENDPOINT}?redirect_uri=${redirectUri}`;
    return url;
  }, [redirectUri]);


  const [_request, result, promptAsync] = AuthSession.useAuthRequest(
    {
      clientId: 'oase.app',
      responseType: AuthSession.ResponseType.IdToken,
      redirectUri,
    }, {
    authorizationEndpoint: PROMISE_ENDPOINT,
  });

  useEffect(() => {
    const res = result as AuthResult;
    console.log(res);
    const idToken = res?.params?.id_token;
    if (idToken) {
      signIn(idToken).then((accessToken) => {
        resetStore();
        setToken(accessToken);
      }).catch(() => {
        alert(t('login.error'));
      });
    }
  }, [result]);

  const doLogin = useCallback(() => {
    if (Platform.OS == "web") {
      window.location.href = loginUrl;
    } else {
      promptAsync({});
    }
  }, [loginUrl, promptAsync]);

  return doLogin;
}
