import { useEffect } from "react";
import SplashScreenAnimation from 'app/components/SplashScreenAnimation';

export default ({
  children,
  ready,
  done,
  run,
}: {
  children: React.ReactElement,
  ready: boolean,
  run: boolean | null,
  done: () => void
}) => {

  useEffect(() => {
    if (run === false) {
      done();
    }
  }, [run]);

  return run ? (
    <SplashScreenAnimation appIsReady={ready} run={run} done={done}>
      <>
        {ready && children}
      </>
    </SplashScreenAnimation>
  ) : ready ? <>{children}</> : null;
};
