import { memo, useState, useEffect, useMemo } from 'react'
import { Text, View, ButtonLinkScale, FullText, Pressable } from 'app/native'
import { Direction } from 'app/design/LogoOne'
import OaseBubble from 'app/components/OaseBubble'
import useProjection from 'app/hooks/useProjection'
import useTranslation from 'app/hooks/useTranslation'
import useNotification from 'app/hooks/useNotification'
import { relativeWithToday } from 'app/hooks/useRelativeTime'
import { iconAndName } from 'app/lib/iconAndName'
import { SuperEllipse } from 'app/design/SuperEllipse'
import { ParticipantAvatar, Participant } from 'app/features/oase/ParticipantAvatar'
import { MotiView, useDynamicAnimation } from 'moti'
import { Platform } from 'react-native'
import { useRouter } from 'solito/router';
import { GestureDetector, Gesture } from 'react-native-gesture-handler';
import { FontAwesomeIcon } from 'app/icon'
import classNames from 'classnames'
import { themeColor } from 'app/design/OaseColor'

type Oase = {
  name: string;
  participants: Participant[];
}

const OaseCard = memo(({ oaseId, updatedAt, placeholder, readyCallback, size }: { readyCallback: () => void, oaseId?: string, updatedAt?: any, placeholder?: boolean, direction: Direction, index: number, size: number }) => {
  const [projection] = useProjection(oaseId ? `feel:oase:${oaseId}` : "", { paginate: false });
  const oase: Oase | undefined = projection?.item;
  const { t } = useTranslation();
  const { unreadCount } = useNotification(oaseId ? `${oaseId}` : "");
  const relativeTime = relativeWithToday(updatedAt || Date.now());
  const [icon, nameWithoutEmoji, isEmoji] = iconAndName(oase?.name || t('hub.oase_card.placeholder'));
  const name = nameWithoutEmoji || t('hub.oase_card.placeholder');
  const badgeColor = `fill-brand-primary`;
  const badgeText = `text-white`;

  useEffect(() => {
    if (projection?.item || placeholder) {
      readyCallback();
    }
  }, [projection?.item]);

  const participantsShowCount = 5;
  const participants: Participant[] | undefined = useMemo(() => oase?.participants?.slice(0, participantsShowCount), [oase?.participants]);
  const hasMoreParticipants = useMemo(() => oase?.participants && (oase?.participants?.length > participantsShowCount), [oase?.participants]);

  // Vars for positioning participants
  const avatarClasses = "w-8 h-8";
  const horisontalSkydning = hasMoreParticipants ? 21 : 23.5;
  const verticalSkydning = hasMoreParticipants ? 17 : 20;
  const leftPadding = 25;
  const bottomPadding = hasMoreParticipants ? 22 : 18;
  const numberPadding = -3;

  return (
    <>
      <View style={{ width: size, height: size }} className="flex flex-col justify-start items-center">
        {placeholder ? null : <View className="flex w-full flex-col justify-center items-start pl-6 pr-5 pt-9">
          <View className="flex flex-row android:items-end ios:items-center web:items-center gap-1">
            {isEmoji ? (
              <Text className="text-sm opacity-70">{icon}</Text>
            ) : null}
            <FullText numberOfLines={1} ellipsizeMode="tail" className={"text-brand-black-400 web:w-36 text-xs font-t-medium"}>
              {relativeTime.charAt(0).toUpperCase() + relativeTime.slice(1)}
            </FullText>
          </View>
          <FullText numberOfLines={2} ellipsizeMode="tail" className={`text-brand-black-900 web:w-36 text-[17px] web:leading-tight leading-5 tracking-tight font-t-semi pt-1`}>{name}</FullText>
        </View>}

        <View style={{ width: size }} className="grow opacity-90">

          {participants?.map((participant: any, index: number) => {
            return (index % 2 == 1) ? null : (
              <View className="absolute"
                key={index}
                style={{ bottom: bottomPadding + verticalSkydning * ((index + 1) % 2), left: leftPadding + horisontalSkydning * index }}
              >
                <ParticipantAvatar className={avatarClasses} key={index} participant={participant} />
              </View>
            );
          })}
          {participants?.map((participant: any, index: number) => {
            return (index % 2 == 0) ? null : (
              <View className="absolute"
                key={index}
                style={{ bottom: bottomPadding + verticalSkydning * ((index + 1) % 2), left: leftPadding + horisontalSkydning * index }}
              >
                <ParticipantAvatar className={avatarClasses} key={index} participant={participant} />
              </View>
            );
          })}
          {hasMoreParticipants && oase?.participants ? (
            <View className="absolute h-6 flex items-center justify-center"
              style={{ bottom: bottomPadding + numberPadding, left: leftPadding + horisontalSkydning * participantsShowCount }}
            >
              <Text className="pl-1 text-brand-black-400 font-t-bold text-xs text-center leading-none">+{oase?.participants?.length - participantsShowCount}</Text>
            </View>
          ) : null}

        </View>
      </View>
      <View
        className="absolute top-3 -right-[2px]"
      >
        {placeholder || unreadCount == 0 ? null : (
          <SuperEllipse
            className="w-7 h-7 flex items-center justify-center"
            fill={badgeColor} >
            <Text className={`${badgeText} font-t-bold text-sm text-center leading-none`}>{unreadCount}</Text>
          </SuperEllipse>

        )}
      </View>
    </>
  );
});

export default memo((props: any) => {
  const { index, oaseId, direction, placeholder, show, animate, size } = props
  const [oase] = useProjection(oaseId ? `feel:oase:${oaseId}` : "", { paginate: false });
  const ourDelay = animate ? index * 50 : 0;
  const [doneWaiting, setDoneWaiting] = useState(false);
  const [ready, setReady] = useState(Platform.OS == "android" || false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [animationDone, setAnimationDone] = useState(!animate);
  const { push } = useRouter();
  const path = oaseId ? `/oase/${oaseId}` : "";

  const textColor = themeColor(oase?.item?.color, "primary", "text", "light");

  const textAnimation = useDynamicAnimation(() => {
    return {
      scale: 1,
      opacity: 0,
    }
  })

  useEffect(() => {
    if (animationDone) {
      textAnimation.animateTo({
        scale: 1,
        opacity: 1,
      });
    }
  }, [animationDone, textAnimation])

  const pickerAnimation = useDynamicAnimation(() => {
    return {
      scale: 0,
      opacity: 0,
    }
  })

  const activeIndexGivenTouch = (touch: undefined | { x: number, y: number }): -1 | 0 | 1 | 2 | 3 => {
    if (!touch) return -1;

    if (touch.x > 0 && touch.x < size && touch.y > 0 && touch.y < size) {
      if (touch.x > size / 2) {
        if (touch.y > size / 2) {
          // Bottom right
          return 3;
        } else {
          // Top right
          return 1;
        }
      } else {
        if (touch.y > size / 2) {
          // Bottom left
          return 2;
        } else {
          // Top left
          return 0;
        }
      }
    }

    return -1;
  };

  const pan = Gesture.Pan().
    activateAfterLongPress(300).
    shouldCancelWhenOutside(false).
    runOnJS(true).
    onStart(() => {
      textAnimation.animateTo({
        scale: 0,
        opacity: 0,
      })
      pickerAnimation.animateTo({
        scale: 1,
        opacity: 1,
      })
    }).
    onFinalize((_, success) => {
      if (!success) return;
      pickerAnimation.animateTo({
        scale: 0,
        opacity: 0,
      })
      textAnimation.animateTo({
        scale: 1,
        opacity: 1,
      })
    }).
    onTouchesMove((event) => {
      const touch = event.changedTouches[0];
      setActiveIndex(activeIndexGivenTouch(touch));
    }).
    onEnd((event) => {
      switch (activeIndexGivenTouch(event)) {
        case 0:
          push(`${path}?shouldOpen=camera`);
          break;
        case 1:
          push(`${path}?shouldOpen=gallery`);
          break;
        case 2:
          push(`${path}?shouldOpen=gif`);
          break;
        case 3:
          push(`${path}?shouldOpen=text`);
          break;
      }
    });

  const tapUI = Gesture.Tap().
    onBegin(() => {
      textAnimation.animateTo({
        scale: 0.98,
        opacity: 1,
      })
    });

  const tapNav = Gesture.Tap().
    runOnJS(true).
    onEnd(() => {
      textAnimation.animateTo({
        scale: 1,
        opacity: 1,
      })
      push(path)
    });

  const tap = Gesture.Simultaneous(tapUI, tapNav);

  const composed = Gesture.Race(pan, tap);

  useEffect(() => {
    if (!show) return;

    const timeout = setTimeout(() => {
      setDoneWaiting(true);
    }, ourDelay);
    return () => clearTimeout(timeout);
  }, [show]);

  const pickerClasses = classNames(textColor, "w-6 h-6");

  return (
    <GestureDetector gesture={composed}>
      <View key={oaseId}>
        {doneWaiting ? (
          <View
            className={`absolute top-0 left-0 ${placeholder && "opacity-50"}`}
          >
            {ready ? <OaseBubble animate={animate} done={() => setAnimationDone(true)} size={size} direction={direction} /> : null}
          </View>
        ) : null}
        <MotiView
          transition={{
            type: 'timing',
            duration: 300,
          }}
          state={textAnimation}
        >
          <OaseCard {...props} readyCallback={() => setReady(true)} size={size} />
        </MotiView>
        <View
          className={`absolute top-0 left-0 p-[10%]`}
          style={{
            width: size,
            height: size,
          }}
        >
          <MotiView
            transition={{
              type: 'timing',
              duration: 300,
            }}
            style={{
              flex: 1,
            }}
            state={pickerAnimation}
          >
            <View className="flex-1 flex flex-col items-center justify-around">
              <View className="w-full flex flex-row items-center justify-around">
                <FontAwesomeIcon icon={[(activeIndex == 0 ? "fas" : "fal"), "camera"]} className={pickerClasses} />
                <FontAwesomeIcon icon={[activeIndex == 1 ? "fas" : "fal", "images"]} className={pickerClasses} />
              </View>
              <View className="w-full flex flex-row items-center justify-around">
                <FontAwesomeIcon icon={[activeIndex == 2 ? "fas" : "fal", "gif"]} className={pickerClasses} />
                <View>
                  <Text className={classNames(pickerClasses, "text-base", activeIndex == 3 ? "font-t-black" : "font-t-regular")}>
                    Aa
                  </Text>
                </View>
              </View>
            </View>
          </MotiView>
        </View>
      </View>
    </GestureDetector>
  );
});
