import { Pressable, Text, View } from "app/native"
import usePerson from 'app/hooks/usePerson'
import useMainframe from "app/hooks/useMainframe";
import { useRef, useState } from "react";
import * as Clipboard from 'expo-clipboard';
import useTranslation from "app/hooks/useTranslation";
import { FontAwesomeIcon } from "app/icon";

export const AddToCalendar = () => {
  const user = usePerson();
  const { get } = useMainframe();
  const [url, setUrl] = useState<string>();
  const [copied, setCopied] = useState(false);
  const copiedTimeout = useRef<any>();
  const { t } = useTranslation();

  return (!user || user.ephemeral) ? null : url ? (
    <Pressable
      onPress={() => {
        clearTimeout(copiedTimeout.current);
        Clipboard.setStringAsync(url);
        setCopied(true);
        copiedTimeout.current =
          setTimeout(() => setCopied(false), 3000);
      }}
      className="mt-10">
      <Text className="font-t-regular text-brand-black-800 text-center">
        {t('add_to_calendar.instructions')}
      </Text>
      <Text className="mt-3 font-mono text-brand-black-700 text-center">{url}</Text>
      <View className="flex flex-row items-center mt-2 justify-center space-x-2 ">
        {copied ? (
          <>
            <FontAwesomeIcon icon={['fal', 'clipboard-check']} className="w-5 h-5 text-brand-black-900" />
            <Text className="font-t-regular text-brand-black-900 mt-1">
              {t('add_to_calendar.copied')}
            </Text>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={['fal', 'clipboard']} className="w-5 h-5 text-brand-black-900" />
            <Text className="font-t-regular text-brand-black-900 mt-1">
              {t('add_to_calendar.copy')}
            </Text>
          </>
        )}
      </View>
    </Pressable>
  ) : (
    <Pressable
      className="mt-10 flex flex-row items-center space-x-2"
      onPress={() => {
        if (user?.ephemeral) {
          alert("You must be logged in to add to your calendar.")
          return;
        }

        get('v1/notification/ics').then((res) => {
          setUrl(res.url);
        });
      }}
    >
      <FontAwesomeIcon icon={['fal', 'calendar-arrow-up']} className="w-6 h-6 text-brand-black-300" />
      <Text className="font-t-regular text-brand-black-300 mt-1">
        {t('add_to_calendar.link')}
      </Text>
    </Pressable>
  );
}
