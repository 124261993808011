import { memo, useCallback, useState } from 'react';
import { View, Image, Pressable } from 'app/native';
import useTranslation from 'app/hooks/useTranslation'
import { IconName } from 'app/icon'
import { FontAwesomeIcon } from 'app/icon'
import { LogoOne, Direction } from 'app/design/LogoOne'
import AppStoreIcons from 'app/components/AppStoreIcons'
import { SuperEllipse } from 'app/design/SuperEllipse';
import { MotiView } from 'moti';
import classNames from 'classnames';
import { Button } from 'app/design/Button';
import { CommunityCycler } from './CommunityCycler';
import { Markdown } from 'app/components/markdown';

const images = [
  "/images/landing-hub.png",
  "/images/landing-feed.png",
  "/images/landing-expenses.png",
  "/images/landing-events.png",
  "/images/landing-polls.png",
]

type Drop = {
  x: number,
  y: number
}

export default memo(() => {
  const { t } = useTranslation();
  const [imageIndex, setImageIndex] = useState(0)
  const [drops, setDrops] = useState<Drop[]>([]);

  const points = [
    {
      name: "connectionOverCollection",
      icon: "people-group",
      fillColor: "fill-brand-secondary-red",
      iconColor: "text-white",
      direction: "ne",
    },
    {
      name: "safety",
      icon: "shield-heart",
      fillColor: "fill-brand-secondary-pink",
      iconColor: "text-white",
      direction: "sw",
    },
    {
      name: "transparent",
      icon: "lightbulb-on",
      fillColor: "fill-brand-secondary-yellow",
      iconColor: "text-white",
      direction: "ne",
    },
  ]

  const dryp = useCallback((event: any) => {
    // Add new drop to drops:
    const drop = {
      x: event.pageX,
      y: event.pageY
    }

    setDrops([...drops, drop]);
  }, [drops, setDrops]);

  const features = [
    {
      name: "oase",
      icon: () => <LogoOne size={20} fill="fill-brand-primary" />,
    },
    {
      name: "one_on_one",
      icon: () => <FontAwesomeIcon icon={[`fas`, `comments`]} className="text-brand-primary w-7 h-7" />,
    },
    {
      name: "weshare",
      icon: () => <FontAwesomeIcon icon={[`fas`, `money-bill-transfer`]} className="text-brand-primary w-7 h-7" />,
    },
    {
      name: "polls",
      icon: () => <FontAwesomeIcon icon={[`fas`, `chart-simple`]} className="text-brand-primary w-6 h-6 -rotate-90 scale-y-[-1]" />,
    },
    {
      name: "events",
      icon: () => <FontAwesomeIcon icon={[`fas`, `calendar-star`]} className="text-brand-primary w-5 h-5" />,
    },
    {
      name: "images",
      icon: () => <FontAwesomeIcon icon={[`fas`, `images`]} className="text-brand-primary w-6 h-6" />,
    },
  ];


  return (
    <>
      {drops.map((drop, index) => (
        <MotiView
          key={index}
          // @ts-ignore
          style={{
            position: "fixed",
            top: drop.y,
            left: drop.x - 15,
            zIndex: 1,
            pointerEvents: "none",
          }}
          from={{
            opacity: 1,
            translateY: 0,
          }}
          animate={{
            opacity: 0,
            translateY: 50,
          }}
          transition={{
            type: "timing",
            delay: 100,
            duration: 400
          }}
        >
          <span className="text-xl">
            💧
          </span>
        </MotiView>
      ))}
      <main className="relative z-0 w-screen -mt-24">
        <div className="relative isolate">
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-brand-secondary-pink to-brand-primary opacity-30"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-6xl font-t-bold tracking-tight text-gray-900">
                    {t("landing.title")}
                  </h1>
                  <p className="mt-4 text-2xl leading-relaxed">
                    <Markdown
                      fontSize={25}
                      text={t("landing.subtitle")}
                    />
                  </p>
                  <View className="mt-2">
                    <Markdown
                      fontSize={25}
                      text={t("landing.dream")}
                    />
                  </View>
                  <p className="mt-4 text-[30px] font-t-bold leading-tight">
                    {t("landing.cta_caption")} <CommunityCycler className="text-[30px] leading-tight font-t-bold tracking-tight text-brand-secondary-green" />
                  </p>
                  <div className="mt-4 flex flex-col items-start">
                    <div className="group relative"
                    >
                      <Button
                        href="/new"
                        className="hover:scale-105 transition relative z-10"
                        text={t("landing.cta")}
                      />
                      <div
                        style={{
                          cursor: "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>💧</text></svg>\") 16 0, auto"
                        }}
                        onClick={dryp}
                        className="select-none pointer-events-none group-hover:pointer-events-auto pt-20 pl-20 pr-4 group-hover:flex absolute -top-28 group-hover:-translate-y-6 opacity-0 group-hover:opacity-100 transition-all text-6xl -right-2 z-0">

                        🌱
                      </div>
                    </div>
                    <View className="flex flex-row justify-center mt-10 -ml-3">
                      <AppStoreIcons />
                    </View>
                  </div>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src="https://images.unsplash.com/photo-1528605248644-14dd04022da1?q=80&w=528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src="https://images.unsplash.com/photo-1607748862156-7c548e7e98f4?q=80&w=528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://images.unsplash.com/photo-1511214378978-61583b84425d?q=80&w=528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src="https://images.unsplash.com/photo-1660733344431-f6a440cb7c6e?q=80&w=528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://images.unsplash.com/photo-1609113006359-1e9c378ab54d?q=80&w=528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <View className="w-screen">
        <div className="overflow-hidden py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pt-4">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-t-semi leading-7 text-brand-primary">
                    {t("landing.more_than_a_group_chat.deeper")}
                  </h2>
                  <p className="mt-2 text-3xl font-t-bold tracking-tight text-gray-900 sm:text-4xl">
                    {t("landing.more_than_a_group_chat.title")}
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    {t("landing.more_than_a_group_chat.description")}
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-12">
                        <dt className="inline font-t-semi text-gray-900">
                          <View className="absolute left-1 top-1">
                            <feature.icon />
                          </View>
                          {t(`landing.features.${feature.name}.title`)}
                        </dt>{' '}
                        <dd className="inline">
                          {t(`landing.features.${feature.name}.description`)}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="flex items-center justify-center order-first">
                <Pressable
                  onPress={() => {
                    // Set next image index, and cycle
                    setImageIndex((imageIndex + 1) % images.length)
                  }}
                  className="flex flex-col items-center"
                >
                  <View
                    className="relative z-10 w-72 mt-10 md:mt-0 mx-0 lg:w-80 transition-transform"
                  >
                    {images.map((image, index) => (
                      <View
                        key={index}
                        className={classNames({
                          "absolute top-0 left-0": index != 0,
                          "relative": index == 0,
                          "z-10": index == imageIndex,
                          "z-0": index != imageIndex,
                        })}
                      >
                        <MotiView
                          from={{ opacity: 0 }}
                          animate={{
                            opacity: index == imageIndex ? 1 : 0
                          }}
                          transition={{ type: "timing", duration: 300 }}
                          key={index}
                        >
                          <Image
                            alt='Oase feed' width={950} height={1867}
                            contentFit="contain" src={image} />
                        </MotiView>
                      </View>
                    ))}

                  </View>
                  <View className="flex flex-row items-center justify-center mt-3 mb-6 md:mb-0">
                    {images.map((_image, index) => (
                      <Pressable
                        onPress={() => {
                          setImageIndex(index)
                        }}
                        className="p-2"
                        key={`${imageIndex}${index}`}>
                        <SuperEllipse className="w-3 h-3" fill={index == imageIndex ? "fill-brand-black-700" : "fill-brand-black-400"} />
                      </Pressable>
                    ))}
                  </View>
                </Pressable>
              </div>
            </div>
          </div>
        </div>
      </View>


      <View className="w-screen mt-20">
        <div className="relative ">
          <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
            <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
              <div className="relative h-96 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
                <img
                  className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
                  src="https://images.unsplash.com/photo-1571867424488-4565932edb41?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.5&fp-y=.1&w=2560&h=3413&&q=80"
                  alt=""
                />
              </div>
            </div>
            <div className="px-6 lg:contents">
              <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
                <p className="text-base font-t-semi leading-7 text-brand-primary">
                  {t("landing.get_started.how_does_it_work")}
                </p>
                <h1 className="mt-2 text-3xl font-t-bold tracking-tight text-gray-900 sm:text-4xl">
                  {t("landing.get_started.title")}
                </h1>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  {t("landing.get_started.description")}
                </p>
                <div className="mt-10 max-w-xl text-base leading-7 text-gray-700 lg:max-w-none">
                  <ul role="list" className="mt-8 space-y-8 text-gray-600">
                    <li className="flex gap-x-3">
                      <FontAwesomeIcon icon={[`fal`, `user`]} className="mt-1 text-brand-primary w-7 h-7" />
                      <span>
                        <strong className="font-t-semi text-gray-900 pr-1">
                          1. {t("landing.get_started.one.title")}
                        </strong>
                        {t("landing.get_started.one.description")}
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <FontAwesomeIcon icon={[`fal`, `plus`]} className="mt-1 text-brand-primary w-7 h-7" />
                      <span>
                        <strong className="font-t-semi text-gray-900 pr-1">
                          2. {t("landing.get_started.two.title")}
                        </strong>
                        {t("landing.get_started.two.description")}
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <FontAwesomeIcon icon={[`fal`, `qrcode`]} className="mt-1 text-brand-primary w-7 h-7" />
                      <span>
                        <strong className="font-t-semi text-gray-900 pr-1">
                          3. {t("landing.get_started.three.title")}
                        </strong>
                        {t("landing.get_started.three.description")}
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <FontAwesomeIcon icon={[`fal`, `mobile-button`]} className="mt-1 text-brand-primary w-7 h-7" />
                      <span>
                        <strong className="font-t-semi text-gray-900 pr-1">
                          4. {t("landing.get_started.four.title")}
                        </strong>
                        {t("landing.get_started.four.description")}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </View>

      <View className="w-screen">
        <section className="isolate overflow-hidden px-6 lg:px-8">
          <div className="relative mx-auto max-w-2xl py-24 sm:py-32 lg:max-w-4xl">
            <figure className="grid grid-cols-1 items-center gap-x-6 gap-y-8 lg:gap-x-10">
              <div className="relative col-span-2 lg:col-start-1 lg:row-start-2">
                <svg
                  viewBox="0 0 162 128"
                  fill="none"
                  aria-hidden="true"
                  className="absolute -top-12 left-0 -z-10 h-32 stroke-brand-primary/40"
                >
                  <path
                    id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                    d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                  />
                  <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x={86} />
                </svg>
                <blockquote className="text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                  <p>
                    {t("landing.testimonials.1.quote")}
                  </p>
                </blockquote>
              </div>
              <div className="col-end-1 w-16 lg:row-span-4 lg:w-72">
                <img
                  className="rounded-xl bg-indigo-50 lg:rounded-3xl"
                  src="/images/anders.jpg"
                  alt=""
                />
              </div>
              <figcaption className="text-base lg:col-start-1 lg:row-start-3">
                <div className="font-semibold text-gray-900">
                  {t("landing.testimonials.1.name")}
                </div>
                <div className="mt-1 text-gray-500">
                  {t("landing.testimonials.1.title")}
                </div>
              </figcaption>
            </figure>
          </div>
        </section>
      </View>

      <View className="w-screen">
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
          <img
            src="https://images.unsplash.com/photo-1556484687-30636164638b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=2d5f63&sat=-100&exp=-30&blend-mode=multiply"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-center"
          />
          <div className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl">
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#4AC4A9] to-[#F6e07e] opacity-20"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu">
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ec9470] to-[#f6c4b7] opacity-20"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-4xl font-t-bold tracking-tight text-white sm:text-6xl">
                {t(`landing.values.title`)}
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                {t(`landing.values.description`)}
              </p>
            </div>
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
              {points.map((point) => (
                <div key={point.name} className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10">
                  <LogoOne size={51} direction={point.direction as Direction} fill={point.fillColor} className="">
                    <FontAwesomeIcon icon={["fas", point.icon as IconName]} className={`w-6 h-6 ${point.iconColor}`} />
                  </LogoOne>
                  <div className="text-base leading-7">
                    <h3 className="font-t-semi text-white">
                      {t(`landing.points.${point.name}.title`)}
                    </h3>
                    <p className="mt-2 text-gray-300">
                      {t(`landing.points.${point.name}.description`)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </View>

      <View className="w-screen">
        <section className="isolate overflow-hidden px-6 lg:px-8">
          <div className="relative mx-auto flex flex-row max-w-2xl pt-24 sm:pt-32 lg:max-w-4xl">
            <figure className="flex flex-col mx-auto items-center gap-x-6 gap-y-8 lg:gap-x-10">
              <div className="relative">
                <svg
                  viewBox="0 0 162 128"
                  fill="none"
                  aria-hidden="true"
                  className="absolute -top-12 left-0 -z-10 h-32 stroke-brand-primary/40"
                >
                  <path
                    id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                    d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                  />
                  <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x={86} />
                </svg>
                <blockquote className="text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                  <p>
                    {t("landing.testimonials.0.quote")}
                  </p>
                </blockquote>
              </div>
              <figcaption className="text-base self-start">
                <div className="font-semibold text-gray-900">
                  {t("landing.testimonials.0.name")}
                </div>
                <div className="mt-1 text-gray-500">
                  {t("landing.testimonials.0.title")}
                </div>
              </figcaption>
            </figure>
          </div>
        </section>
      </View>
      <View className="w-screen -mb-20">
        <div className="">
          <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-t-bold tracking-tight text-gray-900 sm:text-4xl">
                {t("landing.second_title")}
              </h2>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <div className="group relative"
                >
                  <Button
                    href="/new"
                    className="hover:scale-105 transition relative z-10"
                    text={t("landing.second_cta")}
                  />
                  <div
                    style={{
                      cursor: "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>💧</text></svg>\") 16 0, auto"
                    }}
                    onClick={dryp}
                    className="select-none pointer-events-none group-hover:pointer-events-auto pt-20 pl-20 pr-4 group-hover:flex absolute -top-28 group-hover:-translate-y-6 opacity-0 group-hover:opacity-100 transition-all text-6xl -right-2 z-0">

                    🌱
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </View>
    </>
  );
});
